.dashboard-title {
    font-weight: bold;
    width: 50%;
}

.dashboard-add-mail-container {
    padding: 3rem;
    background: white;
}

.dashboard-email-row {
    background: white;
    padding: 1.5rem 0.5rem;
    margin: 1rem 0;
}

@media (max-width: 576px) {
    .dashboard-title {
        width: 100%;
    }

    .dashboard-email-row {
        word-break: break-all;
    }
}

.magic-link-error {
    margin: 0 20% 5rem 20%;
    text-align: center;
    font-weight: bold;
}