.browser-warning-container {
  background-color: var(--enbw-orange-half);
  color: var(--dark-blue);
  margin: 0 3rem 1rem 3rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.browser-warning-container p {
  opacity: 1;
  margin: 0;
}
