:root {
  --grey2p: #fafafa; /*Main navigation bar*/
  --grey5p: #f2f2f2; /*Website background, flyout menu*/
  --grey10p: #e6e6e6; /*Divider*/
  --grey20p: #cccccc; /*Inactive elements*/
  --grey40p: #999999; /*Text in special cases*/
  --grey50p: #808080;
  --grey60p: #666666; /*Toggle, datepicker, quotation*/
  --grey70p: #666666; /*Data visualization*/
  --grey80p: #333333;
  --enbw-blue: #000099; /*logo*/
  --enbw-orange: #ff9900; /*Icons, active elements, link hover state*/
  --enbw-orange-half: #ff990030;
  --dark-blue: #061671; /*Text links, buttons*/
}
