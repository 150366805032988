.navbar-enbw {
    background-color: var(--grey2p);
    color: var(--dark-blue);
    padding: .5rem 5rem;
    box-shadow: 0 1px 20px #00000014;
}

.nav-link-enbw {
    color: var(--dark-blue) !important;
}

.nav-link-enbw:hover {
    color: var(--enbw-orange) !important;
}

.nav-link-enbw:active {
    color: var(--dark-blue) !important;
}

.logo {
    height: 30px;
}