@import "css/Utils.css";
@import "css/Home.css";
@import "css/Variables.css";
@import "css/Navbar.css";
@import "css/Dashboard.css";
@import "css/BrowserWarning.css";

@font-face {
  font-family: "EnBWDINPro";
  font-style: normal;
  font-weight: 300;
  src: local("EnBWDINPro Light"),
    /*url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), !* Super Modern Browsers *!
        url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), !* Modern Browsers *!*/
    url("assets/fonts/EnBWDINPro Light.TTF") format("truetype");
  /* Safari, Android, iOS */
  /*url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
}

@font-face {
  font-family: "EnBWDINPro";
  font-style: normal;
  font-weight: 400;
  src: local("EnBWDINPro"),
    /*url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), !* Super Modern Browsers *!
        url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), !* Modern Browsers *!*/
    url("assets/fonts/EnBWDINPro.TTF") format("truetype");
  /* Safari, Android, iOS */
  /*url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
}

@font-face {
  font-family: "EnBWDINPro";
  font-style: normal;
  font-weight: 500;
  src: local("EnBWDINPro Medium"),
    /*url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), !* Super Modern Browsers *!
        url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), !* Modern Browsers *!*/
    url("assets/fonts/EnBWDINPro Medium.TTF") format("truetype");
  /* Safari, Android, iOS */
  /*url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
}

@font-face {
  font-family: "EnBWDINPro";
  font-style: normal;
  font-weight: 700;
  src: local("EnBWDINPro Bold"),
    /*url('../fonts/open-sans-v13-latin-regular.woff2') format('woff2'), !* Super Modern Browsers *!
        url('../fonts/open-sans-v13-latin-regular.woff') format('woff'), !* Modern Browsers *!*/
    url("assets/fonts/EnBWDINPro Bold.TTF") format("truetype");
  /* Safari, Android, iOS */
  /*url('../fonts/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); !* Legacy iOS *!*/
}

body {
  background-color: var(--grey5p);
  font-family: -apple-system, BlinkMacSystemFont, "EnBWDINPro", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

@media (max-width: 576px) {
  html {
    font-size: 12px;
  }
}

*,
html {

  scroll-behavior: smooth !important;
}