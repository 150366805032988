.icon-button {
    cursor: pointer;
}

.icon-button:hover {
    opacity: 0.8;
}

.link-enbw {
    color: var(--dark-blue) !important;
    text-decoration-color: var(--enbw-orange) !important;
}

.link-enbw:visited {
    color: var(--grey80p) !important;
}

.link-enbw:hover {
    color: var(--enbw-orange) !important;
}

.link-enbw:active {
    color: var(--dark-blue) !important;
}

.btn-enbw {
    background: var(--dark-blue);
    border-radius: 100px;
    padding: 0.5rem 2rem;
    color: white;
}

.btn-enbw:hover {
    color: white;
}

.btn-enbw-round {
    background: var(--dark-blue);
    border-radius: 100px;
    padding: 0.5rem 1rem;
    color: white;
}

.btn-enbw-round:hover {
    color: white;
}

.btn-round {
    border-radius: 100px;
}

.section-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 2rem 0;
}

.modal-content {
    border-radius: 0 !important;
}

.modal-header {
    border: none !important;
}

.modal-body {
    padding: 0 3rem !important;
}

.modal-footer {
    justify-content: center;
    border: none !important;
    padding: 2rem .75rem;
}

.close {
    font-size: 2.5rem !important;
    font-weight: 400 !important;
    opacity: 1 !important;
}

.input-enbw {
    width: 100%;
    padding: 0.5rem 1rem;
    background: #EDF6FB;
    border: none;
}

.input-enbw:focus {
    border: none;
}

.input-sm {
    width: 25%;
}

.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 12rem - 128px);
}

.enbw-text-warning {
    color: var(--enbw-orange)
}

.divider {
    width: 1px;
    background: var(--dark-blue);
    margin: 0.5rem 0;
}

.font-weight-medium {
    font-weight: 500;
}

@media (min-width: 576px) {

    .modal-sm {
        max-width: 500px !important;
    }
}

@media (max-width: 576px) {
    .input-sm {
        width: 100% !important;
    }
}