.main-container {
  margin: 6rem;
  min-height: calc(100vh - 12rem - 128px);
}

.ie-warning {
  padding: 1rem;
  background: var(--enbw-orange);
  color: white;
  margin-bottom: 2rem;
}

.banner-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.text-container {
  background: white;
}

.banner-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.banner-right .text-container {
  display: none;
}

.banner-right .banner-button-container {
  display: none;
}

.banner-inner-container {
  background: white;
  height: 100%;
  padding: 0;
  align-items: center;
  display: flex;
  position: relative;
}

.banner-right-button-container {
  background: white;
  padding: 2rem;
  margin: 0 2rem;
  display: flex;
  position: absolute;
  height: 16rem;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 21rem;
  right: 0;
}

.btn-banner-right {
  margin: 0 auto;
  color: white;
}

.banner-img {
  width: 100%;
  background-size: cover;
}

.banner-title-line1 {
  font-size: 3.5rem;
  font-weight: bold;
  width: fit-content;
  background: white;
  padding: 2rem 2rem 0 2rem;
  margin: 0;
}

.banner-title-line2 {
  font-size: 2rem;
  font-weight: bold;
  width: fit-content;
  background: white;
  padding: 0 2rem;
  line-height: 3rem;
  margin: 0;
}

.banner-sub-title {
  background: white;
  padding: 1rem 2rem 2rem 2rem;
  width: 90%;
}

.process-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
}

.process-item {
  background: white;
  padding: 2rem;
  margin: 0 2rem;
  display: flex;
  position: relative;
  height: 100%;
}

.process-number {
  color: var(--grey5p);
  font-size: 5.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.process-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.process-icon-container {
  background: var(--enbw-orange);
  border-radius: 117px;
  height: 60px;
  width: 60px;
  padding: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -30px;
  right: -30px;
}

.process-icon {
  width: 30px;
  height: 30px;
}

.invoice-channel-container {
  margin-top: 8rem;
}

.invoice-channel-item {
  background: white;
  padding: 2rem;
}

.invoice-item-square {
  background: white;
  padding: 1.5rem 2.5rem;
  text-align: center;
  width: 40%;
}

.selected {
  border: 4px solid var(--enbw-orange);
}

.invoice-item-square img {
  max-height: 70px;
}

.invoice-item-fit {
  background: white;
  padding: 1.5rem 2.5rem;
  text-align: center;
  width: fit-content;
  width: -moz-fit-content;
}

.invoice-item-fit img {
  max-height: 70px;
}

.invoice-btn-text {
  font-weight: bold;
  font-size: 1rem;
}

.invoice-img {
  height: 100px;
}

.interest-you-container {
  margin-top: 8rem;
}

.interest-you-item {
  position: relative;
  overflow: hidden;
}

.interest-you-item-background {
  height: 100%;
  z-index: -1;
  position: absolute;
  background-size: cover;
  width: 100%;
  transform: scale(1);
  transition: 0.4s all ease-in-out;
}

.interest-you-inner-container {
  height: 300px;
  background-position: center;
  transition: 0.4s all ease-in-out;
  background-color: rgba(0, 0, 0, 0.3);
}

.interest-you-item:hover .interest-you-item-background {
  transform: scale(1.2);
}

.interest-you-item-title {
  font-size: 2rem;
  font-weight: bold;
}

.interest-you-item-subtitle {
  font-size: 1rem;
  font-weight: bold;
}

.image-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  color: white;
  text-decoration: none;
  transition: 0.4s all ease-in-out;
}

.image-link:hover {
  background: var(--enbw-orange);
  opacity: 80%;
  color: white;
  text-decoration: none;
  transition: 0.4s all ease-in-out;
}

.faq-container {
  margin-top: 8rem;
}

.faq-item-container {
  background: white;
  padding: 1.5rem;
}

.faq-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.download-item {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 1.5rem;
  align-items: center;
  font-weight: bold;
  margin: 1rem 0;
}

.contact-container {
  margin: 8rem 30%;
}

.contact-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.footer {
  background: var(--dark-blue);
  color: white;
  align-items: center;
  padding: 2rem 6rem;
}

.footer-link-enbw {
  color: white !important;
  font-weight: 500;
  margin: 0 1rem 1rem 0;
}

.portal-description-container {
  margin-top: 4rem;
}

.portal-description-text {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0 3rem;
}

.footer-link-enbw:hover {
  color: var(--enbw-orange) !important;
  font-weight: 500;
}

.loading {
  position: fixed;
  top: 15%;
  left: 90%;
}

@media (max-width: 1200px) {
  .process-item {
    margin: 4rem 0 0 0;
    height: unset;
  }
}

@media (max-width: 1200px) {
  .banner-left {
    display: none;
  }

  .banner-right {
    background: url("../assets/ballpen-blur-close-up-computer-461077.jpg");
    min-height: 300px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
  }

  .banner-right.banner-right--enkk {
    background: url("../assets/nuclear.jpg");
    background-size: cover;
  }

  .banner-inner-container {
    height: unset;
    background: none;
  }

  .banner-right .banner-img {
    display: none;
  }

  .banner-right .banner-inner-container {
    flex-direction: column;
  }

  .banner-right .text-container {
    display: block;
    margin: 2rem 2rem 0 2rem;
  }

  .banner-right .banner-title-line1 {
    padding: 2rem;
    font-size: 2rem;
  }

  .banner-right .banner-button-container {
    display: unset;
    text-align: center;
    margin-top: 2rem;
  }

  .banner-right-button-container {
    margin: 2rem;
    position: static;
    max-width: none;
  }
}

@media (max-width: 576px) {
  .main-container {
    margin: 6rem 3rem;
  }

  .process-container {
    padding: 0;
  }

  .interest-you-inner-container {
    background-size: cover;
  }

  .contact-container {
    margin: 8rem 0;
  }

  .download-item {
    width: 100% !important;
  }

  .interest-you-item-container:first-of-type {
    margin-bottom: 2rem;
  }
}